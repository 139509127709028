import { ComponentPortal, DomPortal, TemplatePortal } from '@angular/cdk/portal';
import { inject, Injectable, TemplateRef } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { SharedFeatureFacade } from '@portal/shared/data-access';

export type _CommonPortal = TemplatePortal | ComponentPortal<any> | DomPortal;

interface State {
    templateRef: TemplateRef<any> | null;
    portal: _CommonPortal | null;
}

const initialState: State = {
    templateRef: null,
    portal: null,
};

@Injectable()
export class AuthenticatedLayoutBloc extends ComponentStore<State> {
    readonly #featureFacade = inject(SharedFeatureFacade);

    readonly $templateRef = this.selectSignal((state) => state.templateRef);
    readonly $portal = this.selectSignal((state) => state.portal);

    constructor() {
        super(initialState);
    }

    initialize() {
        this.#featureFacade.initialize();
    }

    setTemplate(templateRef: TemplateRef<any> | null) {
        this.patchState({ templateRef });
    }

    setPortal(portal: _CommonPortal | null) {
        this.patchState({ portal });
    }
}
