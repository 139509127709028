import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, signal, Signal } from '@angular/core';

import {
    ApplicationFeatures,
    ApplicationFeaturesAvailability,
    ApplicationFeaturesAvailabilityToken,
    JwtTokenStore,
    SecurityRoles,
} from '@portal/shared/utils';

import { AccountHostItemComponent } from './priority-host-item/account-host-item.component';
import { MoreHostItemComponent } from './priority-host-item/more-host-item.component';
import { NavigationHostItemComponent } from './priority-host-item/navigation-host-item.component';

export interface HostItemConfig {
    id: string;
    host: ComponentType<any>;
    hostInputs: Record<string, unknown>;
}

@Injectable()
export class PriorityNavigationService {
    readonly #features: ApplicationFeaturesAvailability = inject(ApplicationFeaturesAvailabilityToken);

    readonly #jwtTokenStore = inject(JwtTokenStore);

    readonly $appHostItems = signal(this.#getAppHostItems());
    readonly $userHostItems: Signal<HostItemConfig[]> = signal(this.#getUserHostItems());
    readonly $moreHostItem: Signal<HostItemConfig> = signal(this.#getMoreHostItem());

    #getAppHostItems(): HostItemConfig[] {
        const apps: HostItemConfig[] = [];

        if (this.#features[ApplicationFeatures.CALENDAR_APPLICATION]) {
            apps.push({
                id: ApplicationFeatures.CALENDAR_APPLICATION,
                host: NavigationHostItemComponent,
                hostInputs: {
                    title: 'Calendar',
                    icon: 'calendar_today',
                    absolutePath: '/calendar',
                },
            });
        }
        if (this.#features[ApplicationFeatures.CASE_FILE_APPLICATION]) {
            apps.push({
                id: ApplicationFeatures.CASE_FILE_APPLICATION,
                host: NavigationHostItemComponent,
                hostInputs: {
                    title: 'Case Files',
                    icon: 'folder_shared',
                    absolutePath: '/case-files',
                },
            });
        }
        if (this.#features[ApplicationFeatures.CONTACTS_APPLICATION]) {
            apps.push({
                id: ApplicationFeatures.CONTACTS_APPLICATION,
                host: NavigationHostItemComponent,
                hostInputs: {
                    title: 'Contacts',
                    icon: 'account_box',
                    absolutePath: '/contacts',
                },
            });
        }
        if (this.#features[ApplicationFeatures.ACCOUNTING_APPLICATION]) {
            apps.push({
                id: ApplicationFeatures.ACCOUNTING_APPLICATION,
                host: NavigationHostItemComponent,
                hostInputs: {
                    title: 'Accounting',
                    icon: 'table',
                    absolutePath: '/accounting',
                    isIconOutlined: true,
                },
            });
        }
        if (this.#features[ApplicationFeatures.CHARTING_APPLICATION]) {
            apps.push({
                id: ApplicationFeatures.CHARTING_APPLICATION,
                host: NavigationHostItemComponent,
                hostInputs: {
                    title: 'Charting',
                    icon: 'note_alt',
                    absolutePath: '/charting',
                },
            });
        }
        if (this.#features[ApplicationFeatures.TEAM_APPLICATION]) {
            apps.push({
                id: ApplicationFeatures.TEAM_APPLICATION,
                host: NavigationHostItemComponent,
                hostInputs: {
                    title: 'Team',
                    icon: 'group',
                    absolutePath: '/team',
                    isIconOutlined: true,
                },
            });
        }
        if (this.#features[ApplicationFeatures.DEVELOPMENT_APPLICATION]) {
            apps.push({
                id: ApplicationFeatures.DEVELOPMENT_APPLICATION,
                host: NavigationHostItemComponent,
                hostInputs: {
                    title: 'dev',
                    icon: 'code',
                    absolutePath: '/dev',
                },
            });
        }

        return apps;
    }

    #getUserHostItems(): HostItemConfig[] {
        const apps: HostItemConfig[] = [];

        if (this.#features[ApplicationFeatures.ADMIN_CONSOLE_APPLICATION]) {
            if (this.#jwtTokenStore.hasSecurityRole(SecurityRoles.MANAGER)) {
                apps.push({
                    id: ApplicationFeatures.ADMIN_CONSOLE_APPLICATION,
                    host: NavigationHostItemComponent,
                    hostInputs: {
                        icon: 'admin_panel_settings',
                        absolutePath: '/admin-console',
                    },
                });
            }
        }

        if (this.#features[ApplicationFeatures.ACCOUNT_PROFILE_MODAL]) {
            apps.push({
                id: ApplicationFeatures.ACCOUNT_PROFILE_MODAL,
                host: AccountHostItemComponent,
                hostInputs: {
                    icon: 'account_circle',
                },
            });
        }

        return apps;
    }

    #getMoreHostItem(): HostItemConfig {
        return {
            id: 'More',
            host: MoreHostItemComponent,
            hostInputs: {
                title: 'More',
                icon: 'more_horiz',
            },
        };
    }
}
