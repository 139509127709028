import { Directive, inject } from '@angular/core';

import { JwtTokenStore, RBACUtils, SecurityRoles, RBACContext } from '@portal/shared/utils';

@Directive({
    selector: '[pFtUserContext]',
    exportAs: 'userContext',
})
export class UserContextDirective {
    readonly #jwtStore = inject(JwtTokenStore);

    readonly rbacContext: RBACContext = this.#getUserContext();

    #getUserContext(): RBACContext {
        const userId: number | null = this.#jwtStore.$userId();
        const userRole: SecurityRoles | null = this.#jwtStore.$userRole();

        return {
            userId,
            userRole,

            isTenantAdmin: userRole === SecurityRoles.TENANT_ADMIN,
            isDirector: userRole === SecurityRoles.DIRECTOR,
            isManager: userRole === SecurityRoles.MANAGER,
            isOfficeAdmin: userRole === SecurityRoles.OFFICE_ADMIN,
            isProvider: userRole === SecurityRoles.PROVIDER,

            hasTenantAdmin: RBACUtils.meetsSecureRole(SecurityRoles.TENANT_ADMIN, userRole),
            hasDirector: RBACUtils.meetsSecureRole(SecurityRoles.DIRECTOR, userRole),
            hasManager: RBACUtils.meetsSecureRole(SecurityRoles.MANAGER, userRole),
            hasOfficeAdmin: RBACUtils.meetsSecureRole(SecurityRoles.OFFICE_ADMIN, userRole),
            hasProvider: RBACUtils.meetsSecureRole(SecurityRoles.PROVIDER, userRole),
        };
    }
}
