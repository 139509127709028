import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'p-ft-priority-host-item',
    imports: [NgClass, MatIcon],
    template: `
        @let isActiveBackground = $isActiveBackground();
        @let isActiveIcon = $isActiveIcon();

        <span class="group flex cursor-pointer flex-col items-center md:mb-0 md:mt-3">
            <span
                [ngClass]="isActiveBackground ? ['bg-ds-purple-400 bg-gradient-radial brightness-95'] : []"
                class="text-ds-purple-300 group-hover:text-ds-light-50 mb-1 flex h-8 w-16 items-center justify-center rounded-2xl transition-colors duration-200"
            >
                <mat-icon
                    [ngClass]="{
                        'text-ds-light-50': isActiveIcon,
                        outlined: $isIconOutlined(),
                    }"
                    class="material-icons text-2xl/6"
                    >{{ $icon() }}</mat-icon
                >
            </span>
            @if ($title(); as title) {
                <span
                    [ngClass]="isActiveIcon ? ['!text-ds-light-50'] : []"
                    class="group-hover:text-ds-light-50 text-ds-purple-300 text-nowrap text-xs duration-200 md:block"
                >
                    {{ title }}
                </span>
            }
        </span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityHostItemComponent {
    readonly $isActiveBackground = input.required<boolean>({ alias: 'isActiveBackground' });
    readonly $isActiveIcon = input.required<boolean>({ alias: 'isActiveIcon' });
    readonly $icon = input.required<string>({ alias: 'icon' });
    readonly $title = input<string | null>(null, { alias: 'title' });
    readonly $isIconOutlined = input<boolean>(false, { alias: 'iconClass' });
}
