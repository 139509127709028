import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EventType, Router } from '@angular/router';

import { LogoComponent, PUiClampContainerDirective, PUiClampItemDirective } from '@portal/shared/ui';
import { LogoTypes } from '@portal/shared/utils';

import { HostItemConfig, PriorityNavigationService } from './priority-navigation.service';

@Component({
    imports: [LogoComponent, NgComponentOutlet, PUiClampContainerDirective, PUiClampItemDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'p-ft-priority-aside-navigation',
    template: `
        <aside
            class="text-ds-light-50 bg-ds-dark-500 relative z-10 box-border hidden h-full flex-col overflow-auto bg-gradient-to-r px-2 py-2 md:flex"
        >
            <div class="mb-1.5 flex justify-center text-xl font-semibold text-gray-700">
                <p-ui-logo [type]="LogoTypes.Light" [width]="60" [height]="60" />
            </div>
            <div class="flex grow flex-col overflow-auto">
                <nav aria-label="Main navigation" pUiClampContainer (clampCountChange)="onMoreCountChange($event)" class="overflow-hidden">
                    @for (hostItem of $appHostItems(); let index = $index; track hostItem.id) {
                        <li class="flex flex-col items-center" pUiClampItem [class.opacity-0]="index >= $hiddenIndex()">
                            <ng-template [ngComponentOutlet]="hostItem.host" [ngComponentOutletInputs]="hostItem.hostInputs" />
                        </li>
                    }
                </nav>
                <nav aria-label="More navigation" class="grow">
                    @if ($hasHidden()) {
                        @let moreHostItem = $moreHostItem();
                        <li class="flex flex-col items-center">
                            <ng-template [ngComponentOutlet]="moreHostItem.host" [ngComponentOutletInputs]="moreHostItem.hostInputs" />
                        </li>
                    }
                </nav>
                <nav aria-label="Secondary navigation" class="pt-8">
                    @for (hostItem of $userHostItems(); track hostItem.id) {
                        <li class="flex flex-row items-center justify-center">
                            <ng-template [ngComponentOutlet]="hostItem.host" [ngComponentOutletInputs]="hostItem.hostInputs" />
                        </li>
                    }
                </nav>
            </div>
        </aside>
    `,
})
export class PriorityAsideNavigationComponent implements OnInit {
    protected readonly LogoTypes = LogoTypes;
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    readonly #$hiddenCountSource: WritableSignal<number> = signal(0);
    protected readonly $hasHidden = computed(() => this.#$hiddenCountSource() > 0);
    protected readonly $hiddenIndex = computed(() => this.$appHostItems().length - this.#$hiddenCountSource());

    readonly #navigationAppsService = inject(PriorityNavigationService);

    protected $appHostItems = this.#navigationAppsService.$appHostItems;
    protected $userHostItems = this.#navigationAppsService.$userHostItems;

    protected readonly $moreHostItem: Signal<HostItemConfig> = computed(() => {
        const moreHostItem: HostItemConfig = this.#navigationAppsService.$moreHostItem();
        const hiddenCount: number = this.#$hiddenCountSource();
        const hiddenIndex: number = this.$hiddenIndex();

        return {
            ...moreHostItem,
            hostInputs: {
                ...moreHostItem.hostInputs,
                hiddenCount,
                hiddenIndex,
            },
        };
    });

    ngOnInit(): void {
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
            if (event.type === EventType.NavigationStart) {
                const currentItemIndex = this.$appHostItems().findIndex((item) => item.hostInputs['absolutePath'] === event.url);
                const currentHiddenIndex = this.$moreHostItem().hostInputs['hiddenIndex'] as number;
                const isCurrentItemHidden = currentItemIndex >= currentHiddenIndex;

                if (isCurrentItemHidden) {
                    this.$appHostItems.update((items) => {
                        const itemsCopy = [...items];
                        [itemsCopy[currentHiddenIndex - 1], itemsCopy[currentItemIndex]] = [
                            itemsCopy[currentItemIndex],
                            itemsCopy[currentHiddenIndex - 1],
                        ];

                        return itemsCopy;
                    });
                }
            }
        });
    }

    protected onMoreCountChange(count: number): void {
        this.#$hiddenCountSource.set(count);
    }
}

@Component({
    selector: 'p-ft-priority-bottom-navigation',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <footer class="text-ds-light-50 bg-ds-dark-500 h-15 box-border flex w-full gap-2 pb-4 pt-3 md:hidden">
            <div class="flex w-full grow">
                <nav
                    aria-label="Main navigation"
                    pUiClampContainer
                    (clampCountChange)="onMoreCountChange($event)"
                    class="flex w-full justify-around gap-2 overflow-hidden"
                >
                    @for (hostItem of $appHostItems(); track hostItem.id) {
                        <li pUiClampItem class="flex flex-row items-center" [class.opacity-0]="$index >= $hiddenIndex()">
                            <ng-template [ngComponentOutlet]="hostItem.host" [ngComponentOutletInputs]="hostItem.hostInputs" />
                        </li>
                    }
                </nav>
                <nav aria-label="More navigation" class="grow">
                    @if ($hasHidden()) {
                        @let moreHostItem = $moreHostItem();
                        <li class="flex flex-col items-center">
                            <ng-template [ngComponentOutlet]="moreHostItem.host" [ngComponentOutletInputs]="moreHostItem.hostInputs" />
                        </li>
                    }
                </nav>
            </div>
        </footer>
    `,
    imports: [NgComponentOutlet, PUiClampContainerDirective, PUiClampItemDirective],
})
export class PriorityBottomNavigationComponent implements OnInit {
    private router = inject(Router);
    private destroyRef = inject(DestroyRef);

    readonly #$hiddenCountSource: WritableSignal<number> = signal(0);
    protected readonly $hasHidden = computed(() => this.#$hiddenCountSource() > 0);
    protected readonly $hiddenIndex = computed(() => this.$appHostItems().length - this.#$hiddenCountSource());

    readonly #navigationAppsService = inject(PriorityNavigationService);

    protected $appHostItems = this.#navigationAppsService.$appHostItems;
    protected readonly $userHostItems: Signal<HostItemConfig[]> = this.#navigationAppsService.$userHostItems;

    protected readonly $moreHostItem: Signal<HostItemConfig> = computed(() => {
        const moreHostItem: HostItemConfig = this.#navigationAppsService.$moreHostItem();
        const hiddenCount: number = this.#$hiddenCountSource();
        const hiddenIndex: number = this.$hiddenIndex();

        return {
            ...moreHostItem,
            hostInputs: {
                ...moreHostItem.hostInputs,
                hiddenCount,
                hiddenIndex,
            },
        };
    });

    ngOnInit(): void {
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
            if (event.type === EventType.NavigationStart) {
                const currentItemIndex = this.$appHostItems().findIndex((item) => item.hostInputs['absolutePath'] === event.url);
                const currentHiddenIndex = this.$moreHostItem().hostInputs['hiddenIndex'] as number;
                const isCurrentItemHidden = currentItemIndex >= currentHiddenIndex;

                if (isCurrentItemHidden) {
                    this.$appHostItems.update((items) => {
                        const itemsCopy = [...items];
                        [itemsCopy[currentHiddenIndex - 1], itemsCopy[currentItemIndex]] = [
                            itemsCopy[currentItemIndex],
                            itemsCopy[currentHiddenIndex - 1],
                        ];

                        return itemsCopy;
                    });
                }
            }
        });
    }

    protected onMoreCountChange(count: number): void {
        this.#$hiddenCountSource.set(count);
    }
}

///

@Component({
    selector: 'p-ft-priority-navigation-shell',
    imports: [PriorityAsideNavigationComponent, MatSidenavModule, PriorityBottomNavigationComponent],
    template: `
        <div class="flex h-full w-full">
            <p-ft-priority-aside-navigation />
            <mat-sidenav-container class="w-full grow">
                <mat-sidenav mode="push" fixedInViewport="true" [opened]="true" [mode]="'side'" [fixedTopGap]="0" />

                <mat-sidenav [opened]="true" mode="side" position="end">
                    <div class="w-[600px]">Drawer</div>
                </mat-sidenav>
                <mat-sidenav-content>
                    <div class="flex h-full flex-col overflow-hidden">
                        <main class="relative grow overflow-auto">
                            <ng-content />
                        </main>
                    </div>
                    <p-ft-priority-bottom-navigation />
                </mat-sidenav-content>
            </mat-sidenav-container>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityNavigationShellComponent {}
