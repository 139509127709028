import { ChangeDetectionStrategy, Component, inject, OnInit, output, TemplateRef, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { ContentLoaderSnippet } from '@common/ui';

import { AccountStore } from '@portal/shared/data-access';
import { ContainerShellApiService } from '@portal/shared/ui';

import { AccountDrawerComponent } from '../account-drawer/account-drawer.component';
import { AuthenticatedLayoutApi } from '../authenticated-layout';

@Component({
    selector: 'p-ft-account-menu',
    templateUrl: './account-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatIconModule, AccountDrawerComponent, MatProgressSpinner, ContentLoaderSnippet],
})
export class AccountMenuComponent implements OnInit {
    private readonly $accountDrawer = viewChild.required('accountDrawer', { read: TemplateRef });

    readonly store = inject(AccountStore);

    readonly #containerApi = inject(ContainerShellApiService);
    readonly #authenticatedLayoutApi = inject(AuthenticatedLayoutApi);

    readonly close = output<void>();

    ngOnInit(): void {
        this.store.loadUserInfo();
    }

    protected onViewProfile(): void {
        // this.#containerApi.closeDrawer();
        // this.#containerApi.setDrawer(this.$accountDrawer());
        // this.#containerApi.openDrawer();

        this.#authenticatedLayoutApi.setTemplate(this.$accountDrawer());
        this.close.emit();
    }

    protected onSignOut() {
        // /
    }
}
