<h1 mat-dialog-title class="font-bold">Add address</h1>
@if ($countriesLoading()) {
    <mat-dialog-content>
        <lib-ui-content-loader-snippet />
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        <form [formGroup]="addAddressForm" class="flex flex-col">
            <mat-form-field class="w-60 pt-2 sm:w-96" appearance="outline">
                <mat-label>Country/Region</mat-label>
                <mat-select formControlName="country" placeholder="Country/Region" (selectionChange)="onCountrySelectionChange($event)">
                    @for (country of $countries(); track country.countryCode) {
                        <mat-option [value]="country.countryName">{{ country.countryName }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            @if ($countryRegions().length > 0 && $country()?.regionType) {
                <mat-form-field class="w-60 sm:w-96" appearance="outline">
                    <mat-label>{{ $country()!.regionType }}</mat-label>
                    <mat-select formControlName="state">
                        @for (state of $countryRegions(); track state.provinceCode) {
                            <mat-option [value]="state.provinceName">{{ state.provinceName }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }
            <mat-form-field class="w-60 sm:w-96" appearance="outline">
                <mat-label>Street</mat-label>
                <input formControlName="street" matInput [maxlength]="STREET_MAX_LENGTH" placeholder="Street" type="text" />
            </mat-form-field>
            <mat-form-field class="w-60 sm:w-96" appearance="outline">
                <mat-label>PO Box</mat-label>
                <input formControlName="poBox" matInput [maxlength]="PO_BOX_MAX_LENGTH" placeholder="PO Box" type="text" />
            </mat-form-field>
            <mat-form-field class="w-60 sm:w-96" appearance="outline">
                <mat-label>City/Town</mat-label>
                <input formControlName="city" matInput [maxlength]="CITY_MAX_LENGTH" placeholder="City/Town" type="text" />
            </mat-form-field>

            @if ($country()?.zipLabel) {
                <mat-form-field class="w-60 sm:w-96" appearance="outline">
                    <mat-label>{{ $country()!.zipLabel }}</mat-label>
                    <input formControlName="postalCode" matInput [maxlength]="POSTAL_CODE_MAX_LENGTH" type="text" />
                </mat-form-field>
            }
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="mb-4 mr-4 flex w-full justify-end">
            <button mat-button type="button" (click)="onCancel()">Cancel</button>
            <button class="primary" mat-raised-button type="button" (click)="onAddAddress()" [disabled]="addAddressForm.invalid">
                Save
            </button>
        </div>
    </mat-dialog-actions>
}
